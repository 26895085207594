<!-- 景区管理-会员管理 -->

<template>
  <div class="hotel-member-container main-cnt">
    <div class="title">会员管理</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="ScenicApi.getMemberLists" :columns="tableColumns" @onDetailsBtn="onDetailsBtn"
        @onSupplemRecord="onSupplemRecord" @onMemberProperty="onMemberProperty">
        <template #m_owner="{ row }">
          <div class="flex">
            <div class="authentication" :class="row.m_owner == '1' ? 'certified' : 'unauthenticated'">
              {{ row.m_owner == '1' ? '是' : '否' }}
            </div>
          </div>
        </template>
      </common-table>
    </div>

    <!-- 手工补录 -->
    <w-dialog ref="dialogRef" title="积分补录" width="35%" btnWidth="140px" top="20vh" confirmText="确认"
      @wConfirm="onConfirmBtn">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-form-item label="景区" prop="main_id">
          <el-select v-model="ruleForm.main_id" clearable placeholder="请选择景区">
            <el-option :label="item.label" :value="item.value" v-for="item in storeOptions" :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员ID" prop="m_id">
          <el-input v-model="ruleForm.m_id" readonly placeholder="请输入会员ID"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="m_name">
          <el-input v-model="ruleForm.m_name" readonly placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="积分数额" prop="integration">
          <el-input v-model="ruleForm.integration" clearable placeholder="请输入积分数额" type="number"
            oninput="if(value<0)value=0"></el-input>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 会员房产 -->
    <w-dialog ref="dialogRoomRef" title="会员房产" width="55%" btnWidth="140px" top="20vh" :hideFooter="true">
      <w-title :title_name="'会员信息'"></w-title>
      <el-row :gutter="20" class="member-info">
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员姓名</div>
            <div class="member-info-content">{{ infoDatas.m_realname }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员账号</div>
            <div class="member-info-content">{{ infoDatas.m_mobile }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员积分</div>
            <div class="member-info-content">{{ infoDatas.m_integration }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员性别</div>
            <div class="member-info-content">
              {{ infoDatas.m_sex == '1' ? '男' : infoDatas.m_sex == '2' ? '女' : '未知' }}
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员生日</div>
            <div class="member-info-content">{{ infoDatas.m_birth }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员等级</div>
            <div class="member-info-content">{{ infoDatas.m_level_label }}</div>
          </div>
        </el-col>
      </el-row>
      <div class="m-m-t">
        <div class="flex-btw align-center">
          <w-title :title_name="'会员房产'"></w-title>
          <div>
            <el-button type="primary" round @click="onManualBtn" class="manually-bind"
              v-if="authData.indexOf('m_e3zGjNiwYMsSFhnwNwLoC6cMSs') != -1">手动绑定</el-button>
          </div>
        </div>

        <common-table ref="tableRoomRef" tableHeight="350" :tableData="memberProperty" :columns="roomTableColumns"
          :ispaging="false" class="s-m-t" @onUnbindBtn="onUnbindBtn">
          <template #is_deliver="{ row }">
            <div class="flex align-center">
              <div class="dots" :class="row.is_deliver == '是' ? 'bg-green' : 'bg-orange'"></div>
              <div>{{ row.is_deliver }}</div>
            </div>
          </template>
        </common-table>
      </div>
    </w-dialog>

    <!-- 使用详情 -->
    <MemberDetails ref="refMemberDetails"></MemberDetails>

    <!-- 手动绑定房产 -->
    <ManuallyBind ref="manuallyRef" @submit="onConfirmBinding"></ManuallyBind>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch, reactive, nextTick, } from "vue";
  import { ScenicApi, } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox, } from "element-plus";
  import MemberDetails from "../../member/components/MemberDetails.vue";
  import ManuallyBind from "../../member/components/ManuallyBind.vue";
  import { useStore } from "vuex";

  // 权限
  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );

  onMounted(() => {
    tableRef.value.tableLoad();
  });

  const filters = ref([
    {
      filterType: "search",
      name: "keyword",
      value: "",
      placeholder: "请输入房号、会员账号、姓名或手机号",
    },
    {
      filterType: "select",
      name: "m_owner",
      value: "",
      placeholder: "请选择是否业主",
      options: [{ id: 1, name: '是' }, { id: 0, name: '否' }],
      val: "id",
      lab: "name",
    },
    {
      filterType: "select",
      name: "m_level",
      value: "",
      placeholder: "请选择会员等级",
      options: [{ id: 1, name: '注册会员' }, { id: 2, name: '铜牌会员' }, { id: 3, name: '银牌会员' }, { id: 4, name: '金牌会员' }, { id: 5, name: '钻石会员' }],
      val: "id",
      lab: "name",
    },
  ]);
  /** 表格对象 */
  const tableRef = ref(null);

  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "m_id",
      label: "会员ID",
    },
    {
      prop: "m_realname",
      label: "会员姓名",
    },
    {
      prop: "m_sex",
      label: "会员性别",
      type: "option",
      metaData: ["未知", "男", "女"],
      colorData: [
        "var(--text-color)",
        "var(--text-color)",
        "var(--text-color)",
      ],
    },
    {
      prop: "m_birth",
      label: "会员生日",
    },
    {
      prop: "m_mobile",
      label: "会员电话",
    },
    {
      prop: "m_owner",
      type: "customRender",
      label: "是否业主认证",
    },
    {
      prop: "m_integration",
      label: "积分合计",
    },
    {
      prop: "m_level_label",
      label: "会员等级",
    },
    {
      prop: "m_order_num",
      label: "订单合计",
    },
    {
      prop: "m_ctime",
      label: "注册时间",
      minWidth: 100,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 290,
      bottons: [
        {
          name: "详情",
          action: "onDetailsBtn",
          className: "theme-font-btn",
          token: "n_224c9c2b68d8e57c4fd4934dac8",
        },
        {
          name: "手工补录",
          action: "onSupplemRecord",
          className: "theme-font-btn",
          token: "n_ab1a0277b98590a24562ecfab04",
        },
        {
          name: "会员房产",
          action: "onMemberProperty",
          token: "m_Z4KYtwD8LGP0Fihfe0iCLvrncB",
          className: "theme-font-btn",
        },
      ],
    },
  ]);

  const refMemberDetails = ref(null);  // 详情对话框对象
  /**
   * 
   * 详情按钮
   * 
   * **/
  const onDetailsBtn = (row) => {
    refMemberDetails.value.openDialog(row, ScenicApi);
  }
  const dialogRef = ref(null);  // 手工补录对话框对象
  const formRef = ref(null);
  const storeOptions = ref([]);  // 景区选项
  const ruleForm = ref({
    main_id: '',  // 景区id
    m_id: '',  // 会员id
    m_name: '',  // 会员账号
    integration: '',  // 积分
  });
  const rules = reactive({
    main_id: [
      { required: true, message: "请选择景区", trigger: "change", },
    ],
    m_id: [
      { required: true, message: "请输入会员ID", trigger: "blur", },
    ],
    integration: [
      { required: true, message: "请输入积分数额", trigger: "blur", },
    ],
  });
  /**
   * 
   * 手工补录按钮
   * 
   * **/
  const onSupplemRecord = (row) => {
    ruleForm.value.main_id = '';
    ruleForm.value.integration = '';
    ruleForm.value.m_id = row.m_id;
    ruleForm.value.m_name = row.m_mobile;
    additionalRecording(1);
    dialogRef.value.show();
  }
  /**
   * 
   * 积分补录
   * action  1：获取对应权限列表；2：提交保存
   * 
   * **/
  const additionalRecording = (action) => {
    let params = {
      inu_type: 4,  // 1: 酒店；2：餐饮；3：门店；4：景区；5：开发商
      action: action,
      m_id: ruleForm.value.m_id,
      main_id: ruleForm.value.main_id,
      integration: ruleForm.value.integration,
    };
    dialogRef.value.isLoading = true;
    ScenicApi.additionalRecording(params).then((res) => {
      if (res.Code === 200) {
        if (action == 1) {
          // 查询数据
          storeOptions.value = res.Data.list;
        } else {
          // 提交
          ElMessage.success("录入成功！");
          tableRef.value.tableLoad();
          dialogRef.value.close();
        }
        dialogRef.value.isLoading = false;
      } else {
        ElMessage.error(res.Message);
        dialogRef.value.isLoading = false;
      }
    });
  }
  /**
   * 
   * 手工补录确定按钮
   * 
   * **/
  const onConfirmBtn = () => {
    additionalRecording(2);
  }
  const dialogRoomRef = ref(null);  // 会员房产
  const infoDatas = ref('');  // 详情数据
  const tableRoomRef = ref(null);  // 会员房产表格对象
  const memberProperty = ref([]);  // 会员房产表格数据
  const manuallyRef = ref(null);  // 手动绑定对象
  /** 表格配置数据 */
  const roomTableColumns = ref([
  {
      prop: "project_name",
      label: "项目",
      minWidth: 280,
    },
    {
      prop: "roomnum",
      label: "房号",
      minWidth: 100,
    },
    {
      prop: "buildareas",
      label: "面积",
    },
    {
      prop: "is_deliver",
      label: "是否交房",
      type: 'customRender',
    },
    {
      prop: "mr_create_time",
      label: "认证时间",
      minWidth: 140,
    },
    {
      prop: "type_text",
      label: "认证方式",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "解绑",
          action: "onUnbindBtn",
          token: "m_SR2sGTbcE4by4S2jrIownPyWSg",
          className: "error-font-btn",
        },
      ]
    }
  ]);
  /**
   * 
   * 点击业主是否认证
   * 
   * **/
  const onMemberProperty = (row) => {
    ScenicApi.getMemberRoom({ m_id: row.m_id }).then((res) => {
      if (res.Code === 200) {
        infoDatas.value = res.Data.member;
        dialogRoomRef.value.show();
        getBindRooms(row);
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 获取会员房产表格数据
   * 
   * **/
  const getBindRooms = (row) => {
    ScenicApi.getBindRooms({ m_id: row.m_id }).then((res) => {
      if (res.Code === 200) {
        memberProperty.value = res.Data ? res.Data : [];
        nextTick(() => {
          tableRoomRef.value.tableLoad();
        })
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 点击手动绑定按钮
   * 
   * **/
  const onManualBtn = () => {
    manuallyRef.value.openDialog(infoDatas.value, 'scenic');
  }
  /**
   * 
   * 手动绑定确定按钮
   * 
   * **/
  const onConfirmBinding = (data) => {
    ScenicApi.addBindRoom(data).then((res) => {
      manuallyRef.value.closeDialogLoading();
      if (res.Code === 200) {
        manuallyRef.value.closeDialog();
        ElMessage.success('绑定成功！');
        getBindRooms(data);
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 解绑按钮
   * 
   * **/
  const onUnbindBtn = (row) => {
    ElMessageBox.confirm("是否确认解绑该房产？", "解绑确认", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
    })
      .then(() => {
        ScenicApi.unBindRoom({ mr_id: row.mr_id }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("解绑成功！");
            getBindRooms({ m_id: row.mr_m_id });
            tableRef.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => { });
  }

</script>
<style lang="scss">
  .hotel-member-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }

    .authentication {
      width: 54px;
      height: 24px;
      border-radius: 4px;
      text-align: center;
    }

    .certified {
      background-color: rgba(54, 198, 125, 0.1);
      border: 1px solid #36C67D;
      color: #36C67D;
    }

    .unauthenticated {
      background-color: rgba(240, 23, 10, 0.1);
      border: 1px solid #F0170A;
      color: #F0170A;
    }

    .member-info-title {
      color: #92979E;
      text-align: left;
      width: 80px;
    }

    .member-info-content {
      color: #262C30;
    }

    .room-icon {
      margin-left: 5px;
      margin-top: 1px;
      cursor: pointer;
    }
  }
</style>